module.exports = {
    adicionarDestinatarioExterno: 'Adicionar destinatário externo',
    anexo: 'Anexo',
    anexos: 'Anexos',
    assunto: 'Assunto',
    assuntoMensagemEnviada: 'Assunto',
    ativar: 'Ativar',
    avisoMensagemBloqueia: 'Essa mensagem bloqueia o uso do sistema para o seu usuário. Por favor, entre em contato com a administração do sistema para mais informações.',
    bloqueioTela: 'Bloqueio de tela',
    cabecalho: 'Cabeçalho',
    cabecalhoRodape: 'Cabeçalho/Rodapé',
    cadastroCabecalhoRodape: 'Cadastro de cabeçalho/rodapé',
    cadastroEmailExterno: 'Cadastro de e-mails externos',
    cadastroNotificacao: 'Cadastro de notificação',
    campoObrigatorioDestinatario: 'Informe pelo menos um destinatário',
    complementoCategoria: 'Complemento categoria',
    confirmacaoLeitura: 'Confirmação de leitura',
    confirmarAlteracaoNotificacao: 'Você confirma a alteração desta notificação?',
    confirmarAlterarEmailExterno: 'Você confirma a alteração deste e-mail?',
    confirmarAtivacao: 'Você confirma a reativação desta notificação?',
    confirmarEmail: 'Você confirma o envio deste e-mail?',
    confirmarExclusao: 'Você confirma a exclusão desta notificação?',
    confirmarExclusaoEmailsExternos: 'Você confirma a exclusão deste e-mail externo?',
    confirmarInativacao: 'Você confirma a inativação desta notificação?',
    confirmarNotificacao: 'Você confirma o cadastro desta notificação?',
    confirmarSalvarCabecalhoRodape: 'Você confirma o cadastro deste cabeçalho/rodapé?',
    confirmarSalvarEmailExterno: 'Você confirma o cadastro deste e-mail?',
    dataFinalExibicao: 'Data final de exibição',
    dataHoraCadastro: 'Data/hora cadastro',
    dataHoraEnvio: 'Data/hora de envio',
    dataInicialExibicao: 'Data inicial de exibição',
    destinatarios: 'Destinatários',
    duplicar: 'Duplicar',
    emailEnviado: 'E-mail enviado com sucesso.',
    emailExternoAdicionado: 'E-mail externo adicionado com sucesso.',
    emails: 'E-mails',
    emailsExternos: 'Destinatários externos',
    envio: 'Envio',
    envioEmail: 'Envio de e-mails',
    erroFuncionarioDuplicado: 'Esse funcionário já foi adicionado anteriormente.',
    favorita: 'Favorita',
    favoritar: 'Favoritar',
    filtroDestinatarios: 'Filtrar destinatários',
    filtroObrigatorio: 'É obrigatório informar pelo menos um destinatário para o e-mail.',
    funcionarioAdicionado: 'Funcionário adicionado com sucesso.',
    informeAssunto: 'Informe o assunto',
    instrucaoCabecalhoRodape: 'A partir dessa tela, é possível pesquisar, cadastrar, alterar e excluir templates de cabeçalhos e rodapés de e-mails.',
    instrucaoEmailsExternos: 'A partir dessa tela, é possível pesquisar, cadastrar, alterar e excluir e-mails externos.',
    mensagemEnviada: 'E-mail enviado com sucesso.',
    mensagensEnviadas: 'E-mails enviados',
    moduloMensagens: 'Mensagens',
    notificacaoAlterada: 'Notificação alterada com sucesso!',
    notificacaoCadastrada: 'Notificação cadastrada com sucesso!',
    notificacoes: 'Notificações',
    notificacoesFavoritas: 'Notificações favoritas',
    numeroDestinatarios: 'Número de destinatários',
    outraCategoria: 'Informa a outra categoria',
    periodoExibicao: 'Período de exibição',
    preVisualizacao: 'Pré-visualização de notificação',
    preVisualizar: 'Pré-visualizar',
    responsavelSistema: 'SISTEMA',
    rodape: 'Rodapé',
    salvandoNotificacao: 'Salvando notificação...',
    semCategoria: 'Sem categoria informada',
    sistema: 'Sistema',
    sucessoAtivacao: 'Notificação reativada com sucesso.',
    sucessoExclusao: 'Notificação excluída com sucesso.',
    sucessoInativacao: 'Notificação inativada com sucesso.',
    tipoMensagemEnviada: 'Tipo',
    useFiltros: 'Use os filtros acima para buscar os destinatários'
}