import * as yup from 'yup';
import { CPF, CNS, NOME, DATA_NASCIMENTO } from './fieldNames';

export default (strings) => {
    return yup.object().shape(
        {
            [CPF]: yup
                .string()
                .nullable(),
            [CNS]: yup
                .string()
                .nullable(),
            [NOME]: yup.string()
                .when([CPF, CNS, DATA_NASCIMENTO], {
                    is: (cpf, cns, dataNascimento) => !cpf && !cns && dataNascimento,
                    then: yup
                        .string()
                        .trim()
                        .required(strings.campoObrigatorio),
                    otherwise: yup
                        .string()
                        .nullable()
                        .notRequired()
                    }),
            [DATA_NASCIMENTO]: yup.date().when([CPF, CNS, NOME], {
                is: (cpf, cns, nome) => !cpf && !cns && nome,
                then: yup
                    .date()
                    .nullable()
                    .typeError(strings.dataInvalida)
                    .max(new Date(), strings.erroDataFutura)
                    .required(strings.campoObrigatorio),
                otherwise: yup
                    .date()
                    .nullable()
                    .notRequired()
            }),
        },
        [[NOME, DATA_NASCIMENTO]]
    );
};
