class ValidationError extends Error {
    constructor(message) {
        super(message);
        this.name = 'ValidationError';
        this.message = message;
    }
}

class DataError extends Error {
    constructor(message) {
        super(message);
        this.name = 'DataError';
        this.message = message;
    }
}

class NotFoundError extends Error {
    constructor(message) {
        super(message);
        this.name = 'NotFoundError';
        this.message = message;
    }
}

class DuplicateEntryError extends Error {
    constructor(message) {
        super(message);
        this.name = 'DuplicateEntryError';
        this.message = message;
    }
}

module.exports = {
    ValidationError,
    DataError,
    NotFoundError,
    DuplicateEntryError
};
