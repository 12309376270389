/* eslint-disable import/no-anonymous-default-export */
import * as yup from 'yup'

import dadosGeraisSchema from '@componentes/interno/cadastro/funcionario/dadosGerais/form/validationSchema';
import dadosContatoSchema from '@componentes/interno/cadastro/funcionario/dadosContato/form/validationSchema';
import dadosProfissionaisSchema from '@componentes/interno/cadastro/funcionario/dadosProfissionais/form/validationSchema';
import dadosVinculoInstituicaoSchema from '@componentes/interno/cadastro/funcionario/dadosVinculo/form-instituicao/validationSchema';

export default (strings) => {
    const schema = yup.object().shape({});
    const dadosGerais = dadosGeraisSchema(strings);
    const dadosContato = dadosContatoSchema(strings);
    const dadosProfissionais = dadosProfissionaisSchema(strings);
    const dadosVinculoInstituicao = dadosVinculoInstituicaoSchema(strings);

    return schema
        .concat(dadosGerais)
        .concat(dadosContato)
        .concat(dadosProfissionais)
        .concat(dadosVinculoInstituicao);
}