import { CPF, CNS, NOME, DATA_NASCIMENTO } from "./fieldNames";

const estadoInicial = {
    [CPF]: '',
    [CNS]: '',
    [NOME]: '',
    [DATA_NASCIMENTO]: null
};

export default estadoInicial;
